<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Bruce Bell</h1>

          <p>
            Bruce is a PRP and University of Pikeville alumni. After college
            Bruce has played for several independent teams with his most notable
            being the Lextington Legends, York Revolutions and Charleston Dirty
            Birds of the Atlantic League.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-card max-width="400" max-height="400" hover>
            <v-img
              class="align-center"
              max-width="400"
              max-height="400"
              :src="BruceBell"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="8">
          <h2>Train with Bruce</h2>

          <p>
            Bruce's lessons consist of:

            <br />
            - Proper Warmup/Arm Care

            <br />
            - Catch Play Drills

            <br />
            - Mound Work

            <br />
          </p>

          <p>
            As well as challenging his pitchers to the most
            <strong>ATHLETIC</strong> versions of themself.
          </p>

          <h3>Price</h3>
          <p>
            $50 - 60 minutes

            <br />

            (Group Training - 2 max)
          </p>

          <v-btn href="tel:5028078073" color="primary">Call Now</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col> </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-img class="dark align-center mt-4" :src="PDTrain"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BruceBell from '@/assets/images/BrucePitching.png'
import PDTrain from '@/assets/images/PDTrain.svg'

export default {
  data() {
    return {
      BruceBell: BruceBell,
      PDTrain: PDTrain,
    }
  },
}
</script>

<style></style>
